<template>
    <div
        v-if="$route.name !== 'ChatStory'"
        class="footer-wrap">
        <div
            v-if="showFooterTop || !isMobile()"
            class="top-footer">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <div
                            v-if="currentLocale !== 'ur' && currentLocale !== 'pa' && currentLocale !== 'od'"
                            :class="currentLocale === 'ur'?'right-0 text-right':''"
                            class="foot-title">__("footer_mobile_app")</div>
                        <div
                            v-if="currentLocale !== 'ur'"
                            :class="currentLocale=== 'ur'? 'text-right': ''"
                            class="foot-items">
                            <a
                                href="#"
                                target="_blank"
                                rel="noopener"
                                @click.prevent="triggerEvent">
                                <img
                                    v-lazy="require('../assets/google-play-badge.png')"
                                    alt="google-play"
                                    class="google-play-badge">
                            </a>
                        </div>
                        <div
                            v-if="currentLocale !== 'ur'"
                            :class="currentLocale=== 'ur'? 'text-right': ''"
                            class="foot-items apple-icon">
                            <a
                                href="#"
                                target="_blank"
                                rel="noopener"
                                @click.prevent="triggerEventAppStore">
                                <img
                                    v-lazy="require('../assets/app-store-badge.png')"
                                    alt="app-store"
                                    class="app-store-badge">
                            </a>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div
                            :class="currentLocale=== 'ur'? 'text-right right-0': ''"
                            class="foot-title">__("help_center")</div>
                        <div class="foot-items">
                            <div
                                v-if="getHelpSectionsLoadingState === 'LOADING_SUCCESS'"
                                class="single-item">
                                <router-link
                                    v-for="(eachSection, index) in getHelpSections"
                                    :key="index"
                                    :to="eachSection.pageUrl"
                                    class="section-item">
                                    <i v-if="eachSection.title === 'Writing'" class="pratilipi-icons">&#xe806;</i>
                                    <i v-else-if="eachSection.title === 'Reading'" class="pratilipi-icons">&#xe83f;</i>
                                    <i v-else-if="eachSection.title === 'Copyright'" class="pratilipi-icons">&#xe83e;</i>
                                    <i v-else class="pratilipi-icons">&#xe840;</i>
                                    <span>{{ eachSection.title }}</span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div
                            :class="currentLocale=== 'ur'? 'text-right right-0': ''"
                            class="foot-title">__("footer_contact_us")</div>
                        <div class="foot-items">
                            <div class="single-item">
                                <i class="pratilipi-icons vertical-middle">&#xe838;</i>
                                <a
                                    :class="currentLocale=== 'ur'? 'text-right': ''"
                                    href="tel:__('footer_contact_number')">__("footer_contact_number")</a>
                                <span class="timing">(11am - 8pm)</span>
                            </div>
                            <div class="single-item">
                                <i
                                    :class="currentLocale === 'ur'? 'ml-1': ''"
                                    class="pratilipi-icons vertical-middle">&#xe839;</i>
                                <a
                                    :class="currentLocale=== 'ur'? 'text-right': ''"
                                    href="mailto:__('footer_contact_email')">__("footer_contact_email")</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div
                            :class="currentLocale=== 'ur'? 'text-right right-0': ''"
                            class="foot-title">__("footer_follow_us_on_social_media")</div>
                        <div
                            :class="currentLocale === 'ur'? 'text-right': ''"
                            class="foot-items social">
                            <a
                                href="__('facebook_page')"
                                class="fb"
                                target="_blank"
                                rel="noopener"
                                @click="triggerFbEvent"><icon name="facebook-f"/></a>
                            <a
                                href="__('twitter_page')"
                                class="twitter"
                                target="_blank"
                                rel="noopener"
                                @click="triggerTwEvent"><icon name="twitter"/></a>
                            <a
                                href="__('youtube_page')"
                                class="youtube"
                                target="_blank"
                                rel="noopener"
                                @click="triggerYtEvent"><icon name="youtube-play"/></a>
                            <a
                                href="__('instagram_page')"
                                class="instagram"
                                target="_blank"
                                rel="noopener"
                                @click="triggerInstaEvent"><icon name="instagram"/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer>
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <button
                            class="show-more"
                            @click="toggleTopFooter"><i class="pratilipi-icons">&#xe815;</i></button>
                        <p>
                            © {{ new Date().getFullYear() }} Nasadiya Tech. Pvt. Ltd. |
                            <router-link
                                :class="currentLocale === 'ur' ? 'mx-1' : ''"
                                :to="{ name: 'About_Page' }">__("footer_about_us")</router-link> |
                            <router-link
                                :class="currentLocale === 'ur' ? 'mx-1' : ''"
                                :to="{ name: 'Career_Page' }">__("footer_work_with_us")</router-link> |
                            <router-link
                                :class="currentLocale === 'ur' ? 'mx-1' : ''"
                                :to="{ name: 'Privacy_Page' }">__("footer_privacy_policy")</router-link> |
                            <router-link
                                :class="currentLocale === 'ur' ? 'mx-1' : ''"
                                :to="{ name: 'Terms_Page' }">__("footer_terms_of_service")</router-link>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    import 'vue-awesome/icons/facebook-f';
    import 'vue-awesome/icons/twitter';
    import 'vue-awesome/icons/youtube-play';
    import 'vue-awesome/icons/instagram';
    import { mapActions, mapGetters } from 'vuex';
    import constants from '@/constants';
    import mixins from '@/mixins';

    export default {
        mixins: [
            mixins
        ],
        data () {
            return {
                currentLocale: '',
                showFooterTop: false
            };
        },
        computed: {
            ...mapGetters([
                'getUserDetails'
            ]),
            ...mapGetters('staticpage', [
                'getHelpSections',
                'getHelpSectionsLoadingState'
            ])
        },
        created () {
            this.currentLocale = this.getLanguageCode(process.env.LANGUAGE);

            const currentLocale = process.env.LANGUAGE;
            constants.LANGUAGES.forEach((eachLanguage) => {
                if (eachLanguage.shortName === currentLocale) {
                    this.fetchHelpSections({ language: eachLanguage.fullName.toUpperCase() });
                }
            });
        },
        methods: {
            ...mapActions('staticpage', [
                'fetchHelpSections'
            ]),
            toggleTopFooter () {
                this.showFooterTop = !this.showFooterTop;
                $('.show-more').toggleClass('shown');
                $('.top-footer').toggleClass('shown');
                $('html, body').animate({ scrollTop: $(document).height() }, 'slow');
            },
            triggerEvent () {
                const SCREEN_NAME = this.getAnalyticsPageSource(this.$route.meta.store);
                this.triggerAnanlyticsEvent('GETANDROID_FOOTER_GLOBAL', 'CONTROL', {
                    'USER_ID': this.getUserDetails.userId,
                    SCREEN_NAME
                });
                window.open('https://play.google.com/store/apps/details?id=com.pratilipi.mobile.android&utm_source=web_footer&utm_campaign=app_download');
            },
            triggerEventAppStore () {
                const SCREEN_NAME = this.getAnalyticsPageSource(this.$route.meta.store);
                this.triggerAnanlyticsEvent('GETANDROID_FOOTER_GLOBAL', 'CONTROL', {
                    'USER_ID': this.getUserDetails.userId,
                    SCREEN_NAME
                });
                window.open('https://apps.apple.com/in/app/pratilipi-books-and-stories/id1484810435');
            },
            triggerFbEvent () {
                const SCREEN_NAME = this.getAnalyticsPageSource(this.$route.meta.store);
                this.triggerAnanlyticsEvent('LIKEPRATFB_FOOTER_GLOBAL', 'CONTROL', {
                    'USER_ID': this.getUserDetails.userId,
                    'ENTITY_VALUE': 'FACEBOOK',
                    SCREEN_NAME
                });
            },
            triggerTwEvent () {
                const SCREEN_NAME = this.getAnalyticsPageSource(this.$route.meta.store);
                this.triggerAnanlyticsEvent('LIKEPRATTW_FOOTER_GLOBAL', 'CONTROL', {
                    'USER_ID': this.getUserDetails.userId,
                    'ENTITY_VALUE': 'TWITTER',
                    SCREEN_NAME
                });
            },
            triggerYtEvent () {
                const SCREEN_NAME = this.getAnalyticsPageSource(this.$route.meta.store);
                this.triggerAnanlyticsEvent('LIKEPRATYT_FOOTER_GLOBAL', 'CONTROL', {
                    'USER_ID': this.getUserDetails.userId,
                    'ENTITY_VALUE': 'YOUTUBE',
                    SCREEN_NAME
                });
            },
            triggerInstaEvent () {
                const SCREEN_NAME = this.getAnalyticsPageSource(this.$route.meta.store);
                this.triggerAnanlyticsEvent('LIKEPRATINSTA_FOOTER_GLOBAL', 'CONTROL', {
                    'USER_ID': this.getUserDetails.userId,
                    'ENTITY_VALUE': 'INSTAGRAM',
                    SCREEN_NAME
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .footer-wrap {
        position: relative;
    }
    .top-footer {
        background: #212121;
        color: #fff;
        padding: 15px 0 5px;
        text-align: left;
        margin-top: 20px;
        .foot-title {
            font-size: 15px;
            position: relative;
            padding-bottom: 7px;
            @media screen and (max-width: 768px ) {
                font-size: 13px;
            }
            &:before {
                position: absolute;
                left: 0;
                bottom: 0;
                height: 2px;
                background: #d00b12;
                width: 50px;
                content: "";
            }
        }
        .foot-items {
            margin: 20px 0;
            @media screen and (max-width: 768px ) {
                margin: 5px 0 20px;
            }
            .single-item {
                i {
                    font-size: 14px;
                    vertical-align: middle;
                    padding-right: 10px;
                }
                a {
                    display: inline-block;
                    color: #fff;
                    font-size: 14px;
                    margin-top: 10px;
                    @media screen and (max-width: 768px ) {
                        font-size: 12px;
                    }
                    &.section-item {
                        display: block;
                    }
                }
                span {
                    display: inline-block;
                }
                .timing {
                    font-size: 12px;
                    color: #fefefe;
                }
            }
            &.social a {
                display: inline-block;
                width: 30px;
                height: 30px;
                color: #fff;
                background: #3b5998;
                border-radius: 50%;
                text-align: center;
                line-height: 30px;
                margin-right: 5px;
                .fa-icon {
                    vertical-align: middle;
                }
                &:hover {
                    text-decoration: none;
                    border-bottom: 1px solid;
                }
            }
            &.social a.twitter {
                background: #00aced;
            }
            &.social a.youtube {
                background: #ff0000;
            }
            &.social a.instagram {
                background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
            }
            img.google-play-badge {
                width: 150px;
                margin-left: -10px;
                @media screen and (max-width: 768px ) {
                    width: 110px;
                }
            }
            img.app-store-badge {
                width: 150px;
                padding: 10px;
                margin-left: -10px;
                @media screen and (max-width: 768px ) {
                    width: 110px;
                }
            }
        }
        .apple-icon {
            margin: -20px 0;
        }
    }
    .show-more {
        margin: 0;
        padding: 0;
        text-align: center;
        background: rgba(255,255,255,0.1);
        border: 0;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        float: right;
        margin-left: 10px;
        cursor: pointer;
        transition: all 0.3s;
        i {
            color: #fff;
            vertical-align: middle;
            width: 30px;
            height: 30px;
            line-height: 30px;
        }
        &:focus {
            outline: none;
        }
        &.shown {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
        }
        @media screen and (min-width: 992px ) {
            display: none;
        }
    }
    footer {
        background: #191919;
        color: #fefefe;
        font-size: 13px;
        padding: 10px 0;
        @media screen and (max-width: 992px ) {
            margin-bottom: 48px;
        }
        p {
            margin: 0;
            a {
                color: #fefefe;
            }
        }
    }
    .right-0 {
        &:before {
            right: 0;
        }
    }
</style>
